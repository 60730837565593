import React from 'react'
import AppLogo from './app-logo'

function Header() {
  return (
    <div class='p-5'>
      <div class='flex justify-between items-center'>
        <AppLogo />
      </div>
    </div>
  )
}

export default Header
