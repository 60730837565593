import React, { lazy } from 'react'
import ComponentLoader from './component-loader'
import { Navigate } from 'react-router-dom'
import UnAuthorizeLayout from 'screens/unauthorize/unauthorize-layout'

const ChangePassRequired = ComponentLoader(
  lazy(() => import('screens/ChangePassReq/force'))
)

const Profile = ComponentLoader(lazy(() => import('screens/Profile/profile')))

const ChangePassRequiredRoutes = [
  {
    path: '',
    element: <UnAuthorizeLayout />,
    children: [
      {
        path: 'update-password',
        index: true,
        element: <ChangePassRequired />,
      },
      {
        path: 'profile',
        element: (
          <div className="max-w-3xl mx-auto">
            <Profile />
          </div>
        ),
      },
    ],
  },
  {
    path: '',
    index: true,
    element: <Navigate replace to={'/update-password'} />,
  },
  {
    path: '*',
    index: true,
    element: <Navigate replace to={'/update-password'} />,
  },
]

export default ChangePassRequiredRoutes
