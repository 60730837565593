import Header from 'components/Header/header'
import React from 'react'
import { Outlet } from 'react-router-dom'

function AppLayout() {
  return (
    <div>
      <Header />
      <main className='w-full px-8'>
        <Outlet />
      </main>
    </div>
  )
}

export default AppLayout
