import React, { lazy } from 'react'
import { Navigate } from 'react-router-dom'
import ComponentLoader from './component-loader'
import AuthLayout from 'screens/AuthLayout'

const SignIn = ComponentLoader(lazy(() => import('screens/newsign-in')))
const ChangePass = ComponentLoader(
  lazy(() => import('screens/changepass/email'))
)
const Pass = ComponentLoader(
  lazy(() => import('screens/changepass/newpass'))
)

const AuthRoutes = [
  {
    path: '',
    element: <AuthLayout />,
    children: [
      {
        path: 'login',
        element: <SignIn />,
      },
      {
        path: 'forget-pass',
        children: [
          {
            path: '',
            index: true,
            element: <ChangePass />,
          },
          {
            path: 'reset-pass',
            element: <Pass />,
          },
        ],
      },
      {
        path: '*',
        element: <Navigate to={'/login'} />,
      },
      {
        path: '',
        element: <Navigate to={'/login'} />,
      },
    ],
  },
]

export default AuthRoutes
