import React from 'react'
import Select from 'components/Select/select'
import ProfileButton from 'components/profile-button'
import { useLocationInfo } from 'context/location'
import { useAuth } from 'context/auth'
import Api from 'utils/api'

function Header() {
  const { locations, locationKey, saveLocationKey } = useLocationInfo()
  const { setCurrentUser } = useAuth()

  const handleSelectLocation = async (e) => {
    const { value } = e.target
    const location = await locations.find((loc) => {
      return loc.campaign_code === value
    })
    if (!location) return
    const { response } = await Api('/user', 'put', {
      payload: { selected_church: location.campaign_code },
      notify: true,
    })
    if (response) {
      saveLocationKey(location.campaign_code)
      setCurrentUser(response.data)
    }
  }
  return (
    <div class='p-5'>
      <div class='flex justify-between items-center'>
        <div className='w-1/2'>
          <Select
            onSelect={handleSelectLocation}
            placeholder={'Select Location'}
            value={locationKey}
            options={locations.map((location) => ({
              label: location.name,
              value: location.campaign_code,
            }))}
          />
        </div>

        <ProfileButton />
      </div>
    </div>
  )
}

export default Header
