import React from 'react'
import { useAuth } from 'context/auth'
import AuthRoutes from './auth-routes'
import UserRoutes from './user-routes'
import InvalidAccessRoutes from './unauthorize-routes'
import { ROLES, TYPES } from 'enums/user'
import ChangePassRequiredRoutes from './change-pass-req-routes'

const Routes = () => {
  const { currentUser } = useAuth()

  const routes = []

  // PUBLIC
  if (!currentUser) return routes.concat(AuthRoutes)

  const validUserRoles = [ROLES.POS, ROLES.ADMIN, ROLES.TEACHER]
  const validUserTypes = [TYPES.LOCATION, TYPES.TEACHER]

  // PREMISSION DENIED
  if (
    currentUser &&
    !currentUser.roles.some((role) => validUserRoles.includes(role))
  ) {
    return routes.concat(InvalidAccessRoutes)
  }

  // CHANGE PASSWORD REQUIRED
  if (currentUser && currentUser.change_pass_required) {
    return routes.concat(ChangePassRequiredRoutes)
  }
  // USER
  return routes.concat(UserRoutes)
}

export default Routes
